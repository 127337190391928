import { MouseEvent, useCallback } from 'react';
import delayLinkClick from 'lib/utils/delayLinkClick';
import useAppContext, { DeviceType } from '../useAppContext';
import { analyticsLog, asyncTrack } from './analytics-utilities';
import { mxPropsDocument, mxPropsPartnerLink } from './tracked.ssr';

function handlesBuyNowClick(
  clickedElement: HTMLElement,
  deviceType?: DeviceType,
): boolean {
  const partnerLink = clickedElement.closest<HTMLElement>(
    '.partner-link__anchor, .cta-link',
  );

  if (!partnerLink) {
    return false;
  }

  asyncTrack('BUY_NOW_CLICK', mxPropsPartnerLink(partnerLink, deviceType));
  return true;
}

/**
 * Temporary addition for p1 bug WEB-3420
 * Add to all pages as onclick where standard or
 * feature cards are present to handle new mixpanel event
 */
export function tempBuyNowClick() {
  return (event: MouseEvent<HTMLElement>) => {
    const clickedElement = event.target as HTMLElement;
    if (handlesBuyNowClick(clickedElement)) return;
  };
}

/**
 * Track data section and document dataset element
 * @param { |
 *   'RECIPE_LANDING_PAGE_CLICK' |
 *   'REVIEW_LANDING_PAGE_CLICK' } mixpanelEvent
 * @returns
 */
export default function useTrackPageClickCallback(
  mixpanelEvent:
    | 'RECIPE_LANDING_PAGE_CLICK'
    | 'REVIEW_LANDING_PAGE_CLICK'
    | 'LANDING_PAGE_CLICK', // eslint-disable-line
) {
  const { deviceType } = useAppContext();

  return useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      const clickedElement = event.target as HTMLElement;
      const clickedLink = clickedElement.closest('a');
      const btn = clickedElement.closest('button');
      const { sectionType, trackButtons } =
        clickedElement.closest<HTMLDivElement>('[data-section-type]')
          ?.dataset ?? {};

      // buy now link tracking
      if (handlesBuyNowClick(clickedElement, deviceType)) return;

      // all other section item tracking that have links
      const dataWrapper = clickedLink?.closest<HTMLElement>('[data-title]');
      if (!btn && clickedLink && sectionType && dataWrapper) {
        const resumeLinkAction =
          clickedLink.getAttribute('target') !== '_blank'
            ? delayLinkClick(event, clickedLink.href)
            : () => null;

        await asyncTrack(
          mixpanelEvent,
          mxPropsDocument(dataWrapper, deviceType, sectionType),
        );
        resumeLinkAction();
        return;
      }

      // track clicks for non-link types like FAQ buttons if data-track-buttons = 'true'
      if (btn && sectionType && trackButtons) {
        asyncTrack(mixpanelEvent, { type: sectionType, deviceType });
        return;
      }

      analyticsLog('no track page event for click');
    },
    [deviceType, mixpanelEvent],
  );
}

export function mxData(
  props: Record<string, string>,
): Record<'data-mx', string> {
  return { 'data-mx': JSON.stringify(props) };
}
